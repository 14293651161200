import React from 'react';
import styles from './SuccessModalStep.module.scss';
import greenCircleCheck from "../../assets/svg/greenCircleCheck.svg";
import addClassNames from "../../classNameUtils";

type SuccessModalStepProps = {
  image?: string;
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  toggle: () => void;
  className?: string;
}

export default function SuccessModalStep({image, title, subtitle, toggle, className}: SuccessModalStepProps)  {
  return <div className={addClassNames([
    {className: styles.container, condition: true},
    {className: className, condition: !!className},
  ])}>
    <div onClick={toggle} className={styles.toggleIconContainer}>
      <i className={`fa-regular fa-xmark ${styles.toggleIcon}`} />
    </div>
    <img src={image || greenCircleCheck} alt={"greenCircleCheck"}/>
    {typeof title === "string" ? <p className={styles.title}>{title}</p> : title}
    {typeof subtitle === "string" ? <p className={styles.subtitle}>{subtitle}</p> : subtitle}
  </div>
}
