import React, {useEffect} from 'react';
import {graphql, QueryRenderer} from "react-relay";
import {Redirect} from "react-router-dom";
import MyLoaderOverlay from "../../components/LoadingOverlay";
import NoBrandsAvailable from "../../components/noBrandsAvailable/NoBrandsAvailable";
import UserContext from "../../context/UserContext";
import environment, {deleteAuthToken} from "../../Environment";
import {FeatureFlagsProvider} from "../../ui-kit/src/context/FeatureFlagsContext";
import Page500 from "../../ui-kit/src/pages/Page500";
import NoCompanySelected from "../../ui-kit/src/specialized/userForms/NoCompanySelected";
import {handleBrandChange} from "../changeBrand";
import {initializeLoggedInUser} from "../../common/intercomIntegration";


function IntercomInit({children, user}) {
  useEffect(() => {
    initializeLoggedInUser(
      user.firstName + ' ' + user.lastName,
      user.email,
      user.id
    )
  }, [user])
  return children;
}

class LoginRequiredContainer extends React.Component {

  render() {
    return <QueryRenderer
        environment={environment}
        render={({error, props}) => {
          if (!error && !props) {
            return <MyLoaderOverlay active={true}/>
          } else if (error) {
            if (error[0] && error[0].message === "Error decoding signature") {
              deleteAuthToken()
              return <Redirect to={"/login?next=" + window.location.href}/>
            }
            return <Page500/>;
          } else if (props && props.currentUser) {
            if (!props.currentUser.brand) {
              if (props.currentUser.brandSet.edges.length < 1) {
                return <UserContext.Provider value={props.currentUser}>
                  <IntercomInit user={props.currentUser}>
                    <NoBrandsAvailable user={props.currentUser}/>
                  </IntercomInit>
                </UserContext.Provider>;
              } else {
                return <UserContext.Provider value={props.currentUser}>
                  <IntercomInit user={props.currentUser}>
                    <NoCompanySelected
                      selectText={'brand'}
                      options={props.currentUser.brandSet.edges.map(({node}) => ({...node}))}
                      onSelect={(id) => {
                        handleBrandChange({id: id})
                      }}
                    />
                  </IntercomInit>
                </UserContext.Provider>;
              }
            }
            return <UserContext.Provider value={props.currentUser}>
              <IntercomInit user={props.currentUser}>
                <FeatureFlagsProvider flags={props.currentUser.activeFeatureFlags}>
                  {this.props.children}
                </FeatureFlagsProvider>
              </IntercomInit>
            </UserContext.Provider>;
          } else if (props && !props.currentUser) {
            // delete the token because it is invalid anyway since it failed to retrieve the current user
            deleteAuthToken();
            return <Redirect to={"/login?next=" + window.location.pathname}/>
          }
          return <div>:)</div>
        }}
        query={graphql`
        query LoginRequiredContainerQuery {
          currentUser {
            id
            company {
              id
              name
            }
            firstName
            lastName
            email
            avatar
            brand {
              id
              name
              logo
              pendingDataSubscriptionRequests
              hasPermissions {
                manageBrandUsers
                changeBrand
              }
            }
            brandSet {
              edges {
                node {
                  id
                  name
                }
              }
            }
            activeFeatureFlags,
            isSystemAdmin
          },
        }
      `}
    />

  }
}


export default LoginRequiredContainer;
