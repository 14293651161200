import React, {useState} from "react";
import GrayModal from "../../../dataDisplay/GrayModal";
import {noop} from "../../../commons/misc";
import {useHistory} from "react-router";
import styles from "./TriggerPasswordResetModal.module.scss"
import TriggerPasswordResetFlowMutation from "../../../mutations/TriggerPasswordResetFlowMutation";
import {Environment} from "relay-runtime";
import {ModalErrorAlert, PossibleError} from "../../../commons/errors";
import {isEmailFormat} from "../../../stringUtils";
import Input from "../../../atoms/Input";

type TriggerPasswordResetModalProps = {
  environment: Environment
  scope: string
  onSend: () => void
}

export default function TriggerPasswordResetModal({environment, scope, onSend}: TriggerPasswordResetModalProps) {
  const history = useHistory()
  let [email, setEmail] = useState('')
  let [error, setError] = useState<PossibleError>('')

  const onSubmit = () => {
    if (isEmailFormat(email)) {
      setError("")
      TriggerPasswordResetFlowMutation(
        environment,
        {
          scope: scope,
          email: email
        },
        () => {
          onSend()
        },
        (err) => {
          setError("Something went wrong");
        }
      )
    } else {
      setError("This is not a valid email");
    }
  }

  return <GrayModal
    isOpen={true}
    toggle={noop}
    style={{width: "34rem"}}
    title={"Forgotten password"}
    primaryHeaderButton={{text: "Continue", onClick: onSubmit, isDisabled: !email}}
    secondaryHeaderButton={{text: "Cancel", onClick: () => history.push("/login")}}
    bodyContent={
      <div className={"my-4 ml-5"}>
        <p className={styles.instructionsText}>
          Enter your account email and click on <strong>Continue</strong> to receive instructions
        </p>
        <Input
          onKeyDown={(event) => {
            if (event.key === "Enter" && email)
              onSubmit()
          }}
          className={styles.emailInput}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder={"Type in an email"}
          autoFocus/>
        {error && <div className={'mt-1'}>
          <ModalErrorAlert error={error}/>
        </div>}
      </div>
    }/>
}
