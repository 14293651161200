import React, {useState} from "react";
import styles from "./AddCompanyModal.module.scss";
import Input from "../ui-kit/src/atoms/Input";
import GrayModal, {ButtonSpec} from "../ui-kit/src/dataDisplay/GrayModal";
import CreateBrandMutation from "../mutations/CreateBrandMutation";
import environment from "../Environment";
import {ourToast} from "../ui-kit/src/atoms/Toast";
import SuccessModalStep from "../ui-kit/src/dataDisplay/successModalStep/SuccessModalStep";

enum STEPS {
  COMPANY,
  SUCCESS
}

type AddCompanyModalProps = {
  isModalOpen: boolean,
  id: string,
  toggle: () => void
}

export default function AddCompanyModal({isModalOpen, id, toggle}: AddCompanyModalProps) {
  const [step, setStep] = useState<STEPS>(STEPS.COMPANY)
  const [name, setName] = useState("")

  let title: string | undefined;
  let bodyContent: JSX.Element;
  let primaryButton: ButtonSpec | undefined;
  let secondaryButton: ButtonSpec | undefined;
  let toggleModal: () => void
  if (step === STEPS.COMPANY) {
    title = "Adding a brand";
    toggleModal = toggle;
    bodyContent = <div className={"px-5 py-4"}>
      <Input
        className={styles.input}
        placeholder={"Type in brand name"}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </div>
    primaryButton = {
      isDisabled: !name,
      text: "Add brand", onClick: () => {
        CreateBrandMutation(
          environment,
          {
            name: name,
            companyId: id
          },
          () => {
            setStep(STEPS.SUCCESS)
          },
          err => {
            ourToast("error", "Something went wrong!", err[0].message);
          }
        )
      }
    }
    secondaryButton = {
      text: "Cancel",
      onClick: toggle
    }
  } else {
    toggleModal = () => {
      window.location.href = '/distributedata/retailers'
    };
    bodyContent = (
      <SuccessModalStep
        title={"Brand added"}
        subtitle={"Add more brands from the top-right menu."}
        toggle={toggleModal}/>
    )
  }

  return <GrayModal toggle={toggleModal}
                    isOpen={isModalOpen}
                    className={styles.modal}
                    primaryHeaderButton={primaryButton}
                    secondaryHeaderButton={secondaryButton}
                    title={title}
                    bodyContent={bodyContent}
  />
}
