import React, {useState} from "react";
import {
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
  //@ts-ignore
} from "@coreui/react";
import DefaultHeader from "../../containers/DefaultLayout/DefaultHeader";
import Card from "../../ui-kit/src/atoms/Card"
import Button from "../../ui-kit/src/atoms/Button"
import styles from "./NoBrandsAvailable.module.scss"
import AddCompanyModal from "../AddCompanyModal";
import {SidebarLink, SidebarSeparator, SidebarTitle} from "../../ui-kit/src/sidebar/SidebarComponents";
import {useLocation} from "react-router-dom";
import featureFlags from "../../featureFlags";
import {
  LoginRequiredContainerQueryResponse
} from "../../containers/LoginRequiredContainer/__generated__/LoginRequiredContainerQuery.graphql";
import NoContentAvailable from "../../ui-kit/src/dataDisplay/NoContentAvailable";
import noContentStyles from "../../ui-kit/src/dataDisplay/NoContentAvailable.module.scss";
import noBrands from "../../ui-kit/src/assets/noContentPlaceholders/noBrandSubscriptions.svg"
import WelcomeToVuuhModal from "./WelcomeToVuuhModal";

type NoBrandsAvailableProps = {
  user: LoginRequiredContainerQueryResponse["currentUser"]
}

export default function NoBrandsAvailable({user}: NoBrandsAvailableProps) {
  const location = useLocation();
  const showOrders = featureFlags.ordersDisplayOrdersPageNavigation;
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(true);
  const [isAddCompanyModalOpen, setIsCompanyModalOpen] = useState(false);

  const toggleWelcomeModal = () => {
    setIsWelcomeModalOpen(!isWelcomeModalOpen);
  }

  const toggleCompanyModal = () => {
    setIsCompanyModalOpen(!isAddCompanyModalOpen)
  }

  return <div className="app">
    <AppHeader fixed>
      <DefaultHeader user={user}/>
    </AppHeader>
    <div className="app-body">
      <AppSidebar fixed display="lg">
        <AppSidebarHeader/>
        <AppSidebarForm/>
        <AppSidebarNav>
          <SidebarTitle title={"UPLOAD"}/>
          <SidebarLink title={'Collections'} icon={'fa-thin fa-rectangle-history'} link={'/product/collections'}
                       iconFontSize={'0.875rem'} location={location} disabled
                       dataTestId={"sidebar-link-collections"}/>
          <SidebarLink title={'Marketing'} icon={'fa-thin fa-megaphone'} link={'/marketing'} iconFontSize={'0.813rem'}
                       location={location} disabled dataTestId={"sidebar-link-marketing"}/>
          <SidebarTitle title={"DATA"}/>
          <SidebarSeparator/>
          {showOrders &&
            <SidebarLink title={'Orders'} icon={'fa-thin fa-clipboard-list'} link={'/orders'} location={location}
                         disabled dataTestId={"sidebar-link-orders"}/>}
          <SidebarLink title={'Products'} icon={'fa-thin fa-shirt'} link={'/product/catalog'} iconFontSize={'0.875rem'}
                       location={location} disabled/>
          <SidebarTitle title={"CONNECTIONS"}/>
          <SidebarSeparator/>
          <SidebarLink title={'Outputs'} icon={'fa-thin fa-bolt-lightning'} link={'/output'}
                       location={location} disabled dataTestId={"sidebar-link-output"}/>
          <SidebarLink title={'Retailers'} icon={'fa-thin fa-store'} link={'/distributedata/retailers'}
                       iconFontSize={'0.875rem'} location={location} disabled dataTestId={"sidebar-link-retailers"}/>
          <SidebarTitle title={"HELP"}/>
          <SidebarSeparator/>
          <SidebarLink title={'Help Centre'} icon={'fa-thin fa-life-ring'} link={'https://intercom.help/vuuh/en/'}
                       iconFontSize={'0.875rem'} disabled location={location} dataTestId={"sidebar-link-help-centre"}/>
        </AppSidebarNav>
        <AppSidebarFooter />
        <AppSidebarMinimizer />
      </AppSidebar>
      <main className="main">
        <div className={styles.cardContainer}>
          <Card fillSpace border>
            <div className={styles.noContentContainer}>
              <NoContentAvailable image={noBrands}
                                  title={"Add your brand/s"}
                                  subtitle={<p className={noContentStyles.subtitle}>
                                    Your account has been created but you still need<br/>
                                    to add your brand/s to start using Vuuh.
                                  </p>}
                                  actions={
                                    <Button onClick={toggleCompanyModal}
                                            className={"mt-3"}
                                            icon={<i className={"fa-regular fa-plus"}/>}>
                                      Add your brand/s
                                    </Button>
                                  }/>
            </div>
          </Card>
          {isWelcomeModalOpen && <WelcomeToVuuhModal isOpen={isWelcomeModalOpen}
                                                     toggle={toggleWelcomeModal}/>}
          {isAddCompanyModalOpen && user?.company?.id && <AddCompanyModal isModalOpen={isAddCompanyModalOpen}
                                                                          toggle={toggleCompanyModal}
                                                                          id={user.company.id}
          />}
        </div>
      </main>
    </div>
  </div>
}
