import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Container} from 'reactstrap';
import {AppHeader} from '@coreui/react';
import routes from '../../routes';
import DefaultHeader from './DefaultHeader';
import LoginRequiredContainer from "../LoginRequiredContainer";
// eslint-disable-next-line no-restricted-imports
import {ToastContainer} from "react-toastify";
import DefaultSidebar from "./DefaultSidebar";

function DefaultLayout() {

  return <>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
      closeButton={false}
    />
    <LoginRequiredContainer>
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader/>
        </AppHeader>
        <div className="app-body">
          <DefaultSidebar/>
          <main className="main">
            <Container style={{padding: '2rem', height: '100%'}} fluid>
              <Switch>
                {routes.map((route, idx) => {
                    return route.component ? (
                        <Route key={idx} path={route.path} exact={route.exact}
                               name={route.name} render={props => (
                          <route.component {...props} />
                        )}/>)
                      : null;
                  },
                )}
                <Redirect from="/" to="/orders"/>
              </Switch>
            </Container>
          </main>
        </div>
      </div>
    </LoginRequiredContainer>
  </>;
}

export default DefaultLayout;
