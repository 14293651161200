import React from "react";
import GrayModal from "../../ui-kit/src/dataDisplay/GrayModal";
import SuccessModalStep from "../../ui-kit/src/dataDisplay/successModalStep/SuccessModalStep";
import successModalStyles from "../../ui-kit/src/dataDisplay/successModalStep/SuccessModalStep.module.scss";

type WelcomeToVuuhModalProps = {
  isOpen: boolean;
  toggle: () => void;
}

export default function WelcomeToVuuhModal({isOpen, toggle}: WelcomeToVuuhModalProps) {
  return <GrayModal
    isOpen={isOpen}
    toggle={toggle}
    bodyContent={
      <SuccessModalStep
        title={"Welcome to Vuuh!"}
        subtitle={<p className={successModalStyles.subtitle}>
          Your account has been created, which means<br/>
          you are now in good hands!
        </p>}
        toggle={toggle}/>
    }
  />
}